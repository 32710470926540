import React, { useState, useEffect } from "react";
import signature from "../../img/signature/signature_peking_duck.jpg";
import coldAppetizer from "../../img/menu/garlic_flavored_chicken.jpg";
import hotDishes from "../../img/menu/Braised_Lamb_Yellow_Sauce.jpg";
import premiumNoodleSelection from "../../img/menu/Braised_Lamb_Noodles.jpg";
import dumplings from "../../img/gallery/Beef_and_Onion_Dumplings.jpg";
import friedRice from "../../img/gallery/Beef_Fried_Rice.jpg";
import cornSoup from "../../img/menu/Chinese_hot_sour_soup.jpg";
import fresh_juice from "../../img/menu/fresh_juice.jpg";
import meal from "../../img/menu/meal.jpg";
import kiwiMilkshake from "../../img/menu/milkshake.jpg";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Order from "./Order";
import { hoverScale } from "../../componets/StyleStandardize";
import { useTranslation } from "react-i18next";
import "../../componets/TopBottomNavbar.css";
import { Fade, Slide } from "react-reveal";
import menu_icon from "../../img/menu-icon.png";

const FoodItems = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [showbutton, setShowbutton] = useState(false);
  const [all, setAll] = useState(0);
  const handleAdd = (index, array) => {
    // Use the index or another identifier to get the selected item
    const selectedItem = array[index];

    // Update the state to include the selected item in the items array
    setItems((prevItems) => [
      ...prevItems,
      {
        quantity: quantity,
        totalCost: quantity * selectedItem.cost,
        ...selectedItem,
      },
    ]);

    setShowbutton(true);
  };
  const handleDeleteItem = (index) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.id === "my_modal_5") {
        document.getElementById("my_modal_5").close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const closeModal = () => {
    document.getElementById("my_modal_5").close();
  };
  // A
  const Signature = [
    {
      item: t("Peking_Duck(Half)"),
      cost: 5280,
      description: "",
    },
    {
      item: t("Peking_Duck(Full)"),
      cost: 10980,
      description: t("Peking_Duck_desc"),
    },
    {
      item: t("five_spice_chicken"),
      cost: 1980,
      description: t("Five_spice_chicken_desc"),
    },
    {
      item: t("Griddle Beef Offal 乾鍋牛雜"),
      cost: 2840,
      description: t("Steamed_beef_desc"),
    },
    {
      item: t("dunhuang_lou_grilled_fish"),
      cost: 3600,
      description: t("Dunhuang_lou_grilled_fish_desc"),
    },
    {
      item: t("Hand-caught mutton 手抓羊肉"),
      cost: 3200,
      description: t(""),
    },
  ];
  // b
  const Appetizers = [
    {
      item: t("Lemon_Marinated_Chicken"),
      cost: 860,
      description: t("Lemon_Marinated_Chicken_desc"),
    },
    {
      item: t("Appetizing_Chicken_Gizzards"),
      cost: 860,
      description: t("Appetizing_Chicken_Gizzards_desc"),
    },
    {
      item: t("Golden Shrimp Balls with Mustard Sauce 芥末蝦球"),
      cost: 1680,
      description: t(""),
    },
    {
      item: t("Spicy_Aromatic_Beef"),
      cost: 1280,
      description: t("Spicy_Aromatic_Beef_desc"),
    },
    {
      item: t("Tiger Style Dish 虎式魚"),
      cost: 980,
      description: t(""),
    },
    {
      item: t("Authentic_Sauced_Beef"),
      cost: 2070,
      description: t("Authentic_Sauced_Beef_desc"),
    },
    {
      item: t("Chilled_Beef_Slices"),
      cost: 2100,
      description: t("Chilled_Beef_Slices_desc"),
    },
    {
      item: t("Chilled three shreds"),
      cost: 750,
      description: t(""),
    },
    {
      item: t("Green_pepper_preserved_egg"),
      cost: 970,
      description: t("Green_pepper_preserved_egg_desc"),
    },
    {
      item: t("Traditional_Shanghai"),
      cost: 2800,
      description: t("Traditional_Shanghai_desc"),
    },
    {
      item: t("Flavored_Sauce_Radish"),
      cost: 640,
      description: t("Flavored_Sauce_Radish_desc"),
    },
    // {
    //   item: t("Flavored_Sauce_Radish"),
    //   cost: 580,
    //   description: t("Flavored_Sauce_Radish_desc"),
    // },
    {
      item: t("Peanuts_Aged_Vinegar"),
      cost: 750,
      description: t("Peanuts_Aged_Vinegar_desc"),
    },
    {
      item: t("Sichuan_style_Cold_Noodles"),
      cost: 750,
      description: t("Sichuan_style_Cold_Noodles_desc"),
    },
    {
      item: t("Smashed_Cucumbe"),
      cost: 530,
      description: t("Smashed_Cucumbe_desc"),
    },
    {
      item: t("Signature_Sauce_Dried_Tofu"),
      cost: 740,
      description: t("Signature_Sauce_Dried_Tofu_desc"),
    },
    {
      item: t("Baby_Rape_Flowers"),
      cost: 750,
      description: t("Baby_Rape_Flowers_desc"),
    },
    {
      item: t("Onion_Wood_Ear_Mushroom"),
      cost: 640,
      description: t("Onion_Wood_Ear_Mushroom_desc"),
    },
    {
      item: t("Fragrant_Dry_Bean_Curd"),
      cost: 860,
      description: t("Fragrant_Dry_Bean_Curd_desc"),
    },
    {
      item: t("Stir_Fried_Baby_Bok"),
      cost: 970,
      description: t("Stir_Fried_Baby_Bok_desc"),
    },
    {
      item: t("Fungus mixed with walnut kernels 核桃仁拌木耳"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Fried wild vegetables 炒野菜"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Spicy shredded Chicken 麻辣鸡丝"),
      cost: 1880,
      description: t(""),
    },
    {
      item: t("Spicy Hair tail Segments 麻辣鱼尾段"),
      cost: 1680,
      description: t(""),
    },
    {
      item: t("Spicy Okra 麻辣秋葵"),
      cost: 880,
      description: t(""),
    },
  ];

  const HotDishes = [
    {
      item: t("Grilled_Lamb_Chops"),
      cost: 3200,
      description: t("Grilled_Lamb_Chops_desc"),
    },
    {
      item: t("Cumin Stir-Fried_Lamb Chops"),
      cost: 3100,
      description: t("Cumin Stir-Fried_Lamb Chops desc"),
    },
    {
      item: t("Fatty Beef Soaked in Chili Sesame Sauce"),
      cost: 2880,
      description: t("Fatty Beef Soaked in Chili Sesame Sauce_desc"),
    },
    {
      item: t("Braised large yellow croaker"),
      cost: 1600,
      description: t("Braised large yellow croaker desc"),
    },
    {
      item: t("Steamed seabass"),
      cost: 1800,
      description: t("Steamed seabass_desc"),
    },
    {
      item: t("Braised Lamb in Yellow Sauce"),
      cost: 3280,
      description: t("Braised Lamb in Yellow Sauce_desc"),
    },
    {
      item: t("Stir-fried mutton with green onions"),
      cost: 2880,
      description: t("Stir-fried mutton with green onions desc"),
    },
    {
      item: t("Braised Beef Ribs Potatoes"),
      cost: 2880,
      description: t(""),
    },
    {
      item: t("Silken Tofu with Spicy Beef"),
      cost: 2680,
      description: t(""),
    },
    {
      item: t("Flavored Big Plate Chicken"),
      cost: 2180,
      description: t(""),
    },
    {
      item: t("Stir-Fried Beef with Vegetables"),
      cost: 2180,
      description: t(""),
    },
    {
      item: t("Szechuan_Style_Sautéed_Mala_Mixture"),
      cost: 1400,
      description: t(""),
    },
    {
      item: t("Sichuan Sour and Spicy Fish with Pickled Vegetables"),
      cost: 2950,
      description: t(""),
    },
    {
      item: t("Kung pao chicken"),
      cost: 1080,
      description: t(""),
    },
    {
      item: t("Shrimp with Cashew Nuts"),
      cost: 1680,
      description: t(""),
    },
    {
      item: t("Pineapple Chicken Cubes"),
      cost: 1580,
      description: t("Pineapple_Chicken_Cubes_desc"),
    },
    {
      item: t("Scallion_Ginger_Crab"),
      cost: 1980,
      description: t("Scallion_Ginger_Crab_desc"),
    },
    {
      item: t("Sautéed_Prawns_Spicy_Oil"),
      cost: 1980,
      description: t("Sautéed_Prawns_Spicy_Oil_desc"),
    },
    {
      item: t("Half tendon half beef"),
      cost: 3280,
      description: t(""),
    },
    {
      item: t("Bullwhip with green onions"),
      cost: 2880,
      description: t(""),
    },
    {
      item: t("Fresh Pepper Chicken"),
      cost: 1980,
      description: t(""),
    },
    {
      item: t("Golden Soup and fat beef"),
      cost: 2680,
      description: t(""),
    },
    {
      item: t("Twice cooked fish fillet"),
      cost: 1880,
      description: t(""),
    },
    {
      item: t("Chongqing Spicy Chicken"),
      cost: 1880,
      description: t(""),
    },
    {
      item: t("Dry Pot shrimp"),
      cost: 1880,
      description: t(""),
    },
    {
      item: t("Dry Pot Chicken 干锅鸡"),
      cost: 1980,
      description: t(""),
    },
    {
      item: t("Dry Pot Potato Chips 干锅土豆片"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Dry Pot Cauliflower 干锅菜花"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Bear's paw tofu 熊掌豆腐"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Shallot omelette 葱油饼"),
      cost: 680,
      description: t(""),
    },
    {
      item: t("Jinsha corn kernels 金沙玉米"),
      cost: 880,
      description: t(""),
    },
    {
      item: t("Steamed Egg with Shrimp 蝦仁蒸蛋"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Stir-Fried Mixed Vegetables 炒雜菜"),
      cost: 980,
      description: t(""),
    },
    {
      item: t("Mapo Tofu 麻婆豆腐"),
      cost: 880,
      description: t(""),
    },
    {
      item: t("Hand-Shredded Cabbage 手撕包菜"),
      cost: 750,
      description: t(""),
    },
    {
      item: t("Scrambled Eggs with Tomatoes 番茄炒蛋"),
      cost: 680,
      description: t(""),
    },
    {
      item: t("Stir-Fried Baby Bok Choy with Shiitake Mushrooms 香菇炒小白菜"),
      cost: 680,
      description: t(""),
    },
    {
      item: t("Cumin-Flavored Cauliflower 孜然味花菜"),
      cost: 650,
      description: t(""),
    },
    {
      item: t("Iron Plate Vermicelli with Chinese Cabbage 铁板白菜粉丝"),
      cost: 750,
      description: t(""),
    },
    {
      item: t("Fried tripe with green pepper 青椒炒肚片"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Home Style Japanese Tofu 家常日本豆腐"),
      cost: 1980,
      description: t(""),
    },
    {
      item: t("Hot and Sour Potato Shreds 酸辣土豆丝"),
      cost: 750,
      description: t(""),
    },
    {
      item: t("Fried Pea jelly 炒豌粉"),
      cost: 880,
      description: t(""),
    },
    {
      item: t("Stir-fried eggplant with cowpeas 豆炒茄子"),
      cost: 880,
      description: t(""),
    },
    {
      item: t("Garlic-flavored broccoli 蒜蓉西兰花"),
      cost: 980,
      description: t(""),
    },
    {
      item: t("Fried shrimp with snow peas and bell peppers 荷兰豆青椒炒虾仁"),
      cost: 1980,
      description: t(""),
    },
    {
      item: t("Celery and Cashews with Lanzhou Lily 芹菜腰果兰州百合"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("The grain is plentiful 五谷丰登"),
      cost: 1280,
      description: t(""),
    },
  ];

  const Dumplings = [
    {
      item: t("Beef_Onion_Dumplings"),
      cost: 530,
      description: t("Beef_Onion_Dumplings_desc"),
    },
    {
      item: t("Chieve, Egg and Shrimp Dumplings"),
      cost: 530,
      description: t(""),
    },
    {
      item: t("Radish_Lamb_Dumplings"),
      cost: 530,
      description: t("Radish_Lamb_Dumplings_desc"),
    },
  ];

  const Noodles = [
    {
      item: t("Lanzhou_Beef_Noodles"),
      cost: 640,
      description: t("Lanzhou_Beef_Noodles_desc"),
    },
    {
      item: t("Braised_Beef_Noodles"),
      cost: 750,
      description: t("Braised_Beef_Noodles_desc"),
    },
    {
      item: t("Braised_Lamb_Noodles"),
      cost: 750,
      description: t("Braised_Lamb_Noodles_desc"),
    },
    {
      item: t("Beef_Zhajiang_Noodles"),
      cost: 650,
      description: t("Beef_Zhajiang_Noodles_desc"),
    },
    {
      item: t("Cold_Cucumber_Noodles"),
      cost: 580,
      description: t("Cold_Cucumber_Noodles_desc"),
    },
    {
      item: t("Stir_Fried_Egg_Noodles"),
      cost: 640,
      description: t("Stir_Fried_Egg_Noodles_desc"),
    },
    {
      item: t("Scallion_Oil_Noodles"),
      cost: 420,
      description: t("Scallion_Oil_Noodles_desc"),
    },
  ];

  const FriedRice = [
    {
      item: t("Egg_Fried_Rice"),
      cost: 420,
      description: t("Egg_Fried_Rice_desc"),
    },
    {
      item: t("Beef_Fried_Rice"),
      cost: 580,
      description: t("Beef_Fried_Rice_desc"),
    },
    {
      item: t("Chive_Dumplings"),
      cost: 390,
      description: t("Chive_Dumplings_desc"),
    },
    {
      item: t("Beef_Vermicelli_Soup"),
      cost: 420,
      description: t("Beef_Vermicelli_Soup_desc"),
    },
    {
      item: t("Lamb Paomo 羊泡饃"),
      cost: 950,
      description: t(""),
    },
    {
      item: t("Liangpi 涼皮"),
      cost: 600,
      description: t(""),
    },
    {
      item: t("Beef Roujia mo 牛肉肉夾饃"),
      cost: 680,
      description: t(""),
    },
    {
      item: t("Steamed bun 饅頭"),
      cost: 60,
      description: t(""),
    },
    {
      item: t("Steamed twisted roll 蒸扭卷"),
      cost: 60,
      description: t(""),
    },

    {
      item: t("Mango grapefruit sago 芒果西柚西米露"),
      cost: 480,
      description: t(""),
    },
  ];

  const Soup = [
    {
      item: t("Chinese Hot & Sour Soup 酸辣汤"),
      cost: 980,
      description: t(""),
    },
    {
      item: t("Sweet Corn Soup 玉米汤"),
      cost: 750,
      description: t(""),
    },

    {
      item: t("Shrimp Vegetable Soup 虾仁菜汤"),
      cost: 880,
      description: t("Shrimp_Vegetable_Soup_desc"),
    },

    {
      item: t("Wensi Tofu Soup 文思豆腐汤"),
      cost: 1280,
      description: t(""),
    },
    {
      item: t("Medicinal Pigeon Soup 药膳乳鸽汤"),
      cost: 640,
      description: t(""),
    },
  ];

  // const Juice = [
  //   {
  //     item: t("apple_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("orange_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("watermelon_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("mango_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("pineapple_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("papaya_juice"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("grape_juice"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("dragon_fruit_juice"),
  //     cost: 400,
  //     description: "",
  //   },
  //   {
  //     item: t("lemon_ice_juice"),
  //     cost: 280,
  //     description: "",
  //   },
  //   {
  //     item: t("lassi"),
  //     cost: 280,
  //     description: "",
  //   },
  // ];

  // const Milkshake = [
  //   {
  //     item: t("chocolate_shake"),
  //     cost: 330,
  //     description: "",
  //   },
  //   {
  //     item: t("strawberry_shake"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("mango_shake"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("banana_shake"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("blueberry_shake"),
  //     cost: 400,
  //     description: "",
  //   },
  //   {
  //     item: t("kiwi_shake"),
  //     cost: 400,
  //     description: "",
  //   },
  //   {
  //     item: t("apple_shake"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("Oreo shake"),
  //     cost: 390,
  //     description: "",
  //   },
  //   {
  //     item: t("    Sour Plum Soup"),
  //     cost: 160,
  //     description: "",
  //   },
  // ];

  const Meal = [
    {
      item: t("Potato and Beef set meal 土豆牛肉套餐"),
      cost: 1280,
      description: "",
    },
    {
      item: t("Fish steak set with tomato sauce 番茄酱鱼排套餐"),
      cost: 1280,
      description: "",
    },
    {
      item: t("Braised Chicken Leg set 红烧鸡腿套餐"),
      cost: 1080,
      description: "",
    },
    {
      item: t("Tomato scrambled eggs set 蕃茄炒蛋套餐"),
      cost: 880,
      description: "",
    },
  ];

  const foodTypes = [
    t("all"), // 0
    t("signature"), // 1
    t("cold_appetizers"), // 2
    t("hot_dishes"), // 3
    t("premium_noodle_selection"), // 4
    t("dumplings_jiaozi_selection"), // 5
    t("fried_rice_and_snacks"), // 6
    t("soup"), // 7
    t("juice"), // 8
    t("milkshake"), // 9
  ];

  const foodSets = [
    {
      title: t("signature"),
      item: Signature,
      allValue: 1,
      src: signature,
      alt: t("signature"),
      FoodCode: t("A"),
    },
    {
      title: t("cold_appetizers"),
      item: Appetizers,
      allValue: 2,
      src: coldAppetizer,
      alt: t("Appetizing_Chicken_Gizzards"),
      FoodCode: t("B"),
    },
    {
      title: t("hot_dishes"),
      item: HotDishes,
      allValue: 3,
      src: hotDishes,
      alt: t("Braised_Lamb_Yellow_Sauce"),
      FoodCode: t("C"),
    },
    {
      title: t("premium_noodle_selection"),
      item: Noodles,
      allValue: 4,
      src: premiumNoodleSelection,
      alt: t("Braised_Lamb_Noodles"),
      FoodCode: t("D"),
    },
    {
      title: t("dumplings_jiaozi_selection"),
      item: Dumplings,
      allValue: 5,
      src: dumplings,
      alt: t("Beef_Onion_Dumplings"),
      FoodCode: t("E"),
    },
    {
      title: t("fried_rice_and_snacks"),
      item: FriedRice,
      allValue: 6,
      src: friedRice,
      alt: t("Beef_Fried_Rice"),
      FoodCode: t("F"),
    },
    {
      title: t("soup"),
      item: Soup,
      allValue: 7,
      src: cornSoup,
      alt: t("cornSoup"),
      FoodCode: t("G"),
    },
    {
      title: t("Meal"),
      item: Meal,
      allValue: 8,
      src: meal, // chnage
      alt: t("Meal"), // chnage
      FoodCode: t("H"),
    },
    // {
    //   title: t("juice"),
    //   item: Juice,
    //   allValue: 8,
    //   src: fresh_juice, // chnage
    //   alt: t("Fresh Juice"), // chnage
    //   FoodCode: t("H"),
    // },
    // {
    //   title: t("milkshake"),
    //   item: Milkshake,
    //   allValue: 9,
    //   src: kiwiMilkshake, // chnage
    //   alt: t("Kiwi Milkshake"), // chnage
    //   FoodCode: t("I"),
    // },
  ];

  return (
    <div className="flex flex-col gap-6 py-10 w-full px-2 md:w-3/4 m-auto">
      {/* Buttons */}
      <div className="flex flex-row flex-wrap gap-6 justify-center text-white text-xl font-Playfair font-bold">
        {foodTypes.map((el, i) => {
          return (
            <div className="custom-link-hover">
              <button
                key={i}
                onClick={() => {
                  setAll(i);
                }}
                className={`transition-opacity duration-500 link-text px-1 ${
                  all === i ? "opacity-100" : "opacity-50"
                } ${hoverScale} hover:text-[#E20613] hover:opacity-100`}
              >
                {el}
              </button>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-10">
        {foodSets.map((el, i) => (
          <>
            {(all === 0 || el.allValue === all) && (
              <div key={i} className="flex flex-col font-Lato text-white">
                <div className="flex flex-row px-2">
                  <p
                    className={`text-[#E20613] font-Playfair text-[28px] text-left mb-5 w-32 md:w-52 lg:w-max`}
                  >
                    {el.title}
                  </p>
                  <div className={`ml-auto my-auto ${hoverScale}`}>
                    <a
                      className={`w-max text-white text-sm rounded-full font-semibold cursor-pointer bg-[#E20613] p-3 md:p-4 font-Lato`}
                      href="https://drive.google.com/file/d/1GES8pR_3b2OvtOwgzFvQGrLTxzouLa74/view"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{t("view_full_menu")}</span>
                    </a>
                  </div>
                </div>
                <div
                  className={`flex flex-col ${
                    i % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                  }  gap-6 px-2`}
                >
                  <Slide bottom duration={1000}>
                    <img
                      src={el.src}
                      alt={el.alt}
                      className={`h-80 ${
                        i === 7 || i === 8 ? "w-72" : "w-80"
                      } ${i === 7 ? "mr-auto" : i === 8 ? "md:ml-auto" : ""}`}
                    />
                  </Slide>

                  <div
                    className={`flex flex-col gap-3 ${
                      (i === 7 || i === 8) && "md:mx-auto"
                    }
                     ${(i === 1 || i === 3 || i === 5) && "mr-auto"}`}
                  >
                    {el.item.map((item, index) => (
                      <div
                        key={index}
                        className="text-left flex flex-col gap-3"
                      >
                        <Fade
                          left={i % 2 !== 0}
                          right={i % 2 === 0}
                          duration={1100}
                        >
                          <p className="text-xl text-white font-Playfair mr-auto">
                            {i === 0 ? (
                              index > 1 ? (
                                <p>
                                  {el.FoodCode}
                                  {index}
                                  <img
                                    className="-mt-7 ml-9"
                                    src={menu_icon}
                                    alt="menu-icon"
                                  />
                                </p>
                              ) : (
                                index === 0 && (
                                  <p>
                                    {el.FoodCode}
                                    {index + 1}
                                    <img
                                      className="-mt-7 ml-9"
                                      src={menu_icon}
                                      alt="menu-icon"
                                    />
                                  </p>
                                )
                              )
                            ) : (
                              <p>
                                {el.FoodCode}
                                {index + 1}
                                <img
                                  className="-mt-7 ml-9"
                                  src={menu_icon}
                                  alt="menu-icon"
                                />
                              </p>
                            )}
                          </p>
                        </Fade>
                        <div className="grid grid-cols-2 justify-between text-xl">
                          <Fade
                            left={i % 2 !== 0}
                            right={i % 2 === 0}
                            duration={1100}
                          >
                            {item.item}

                            <div className="flex flex-row gap-6 ml-auto">
                              <p className="text-white ">{item.cost}Tk.</p>
                              <Button
                                index={index}
                                handleshow={() => {
                                  handleAdd(index, el.item);
                                }}
                                inCart={items.some(
                                  (cartItem) => cartItem.item === item.item
                                )} // Check if item is in cart
                              />
                            </div>
                          </Fade>
                        </div>

                        <Fade duration={1100}>
                          {item.description && <p>{item.description}</p>}
                        </Fade>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>

      {showbutton && (
        <button
          className={`${hoverScale} w-max text-white text-sm rounded-full font-semibold cursor-pointer bg-[#E20613] p-3 font-Lato fixed bottom-2 left-[10px]`}
          onClick={() => document.getElementById("my_modal_5").showModal()}
        >
          <ShoppingCartIcon />
          {t("my_cart")}
        </button>
      )}

      <dialog id="my_modal_5" className="modal m-auto w-11/12 md:w-1/2">
        <div className="modal-box flex flex-col gap-5 bg-black text-white">
          <div className="flex flex-col gap-3 text-lg">
            <p className="font-bold flex font-Lato justify-between items-center">
              {t("process_order")}
              <button
                className="w-max hover:tooltip hover:tooltip-bottom"
                data-tip="Close"
                onClick={closeModal}
              >
                <CloseIcon />
              </button>
            </p>
            <p className="text-sm font-Lato text-[#E20613]">
              {t("home_delivery_available")}
            </p>
          </div>
          <Order orderItems={items} handleDeleteItem={handleDeleteItem} />
        </div>
      </dialog>
    </div>
  );
};

const Button = ({ index, handleshow, inCart }) => {
  return (
    <div>
      {inCart ? (
        <ShoppingCartCheckoutIcon />
      ) : (
        <Link
          onClick={() => {
            handleshow(index);
          }}
          className="text-[#E20613] font-bold"
        >
          <AddShoppingCartIcon />
        </Link>
      )}
    </div>
  );
};

export default FoodItems;
